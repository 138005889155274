import moment from 'moment';
import Countdown from 'countdown';

const timerDayEl = document.querySelector(".days")
const timerHourEl = document.querySelector(".hours")
const timerMinEl = document.querySelector(".minutes")
const timerSecEl = document.querySelector(".seconds")

//   const daysEl = document.querySelector(".days");
//   const hoursEl = document.querySelector(".hours");
//   const minutesEl = document.querySelector(".minutes");
//   const secondsEl = document.querySelector(".seconds");
//   const futureDate = new Date("March 4 2018 11:00");
// time difference

// hours*minutes*seconds*milliseconds
// let day = 24 * 60 * 60 * 1000;

// const runTimer = _ => {
//   let timer = setInterval(function () {
//     const startDate = moment()
//     const endDate = "2018-07-29 11:00:00";
//     let ms = Math.abs(startDate.diff(moment(endDate)));
//     let duration = moment.duration(ms);
//     if (duration.seconds < 0) return "Time's up!";

//     timerDayEl.textContent = duration.days();
//     timerHourEl.textContent = duration.hours();
//     timerMinEl.textContent = duration.minutes();
//     timerSecEl.textContent = duration.seconds();
//   }, 1000);
// }

const runTimer = _ => {
  setInterval(_ => {
    let newTimer = Countdown(new Date('2018-07-29 11:00:00'));
    timerDayEl.textContent = newTimer.days;
    timerHourEl.textContent = newTimer.hours;
    timerMinEl.textContent = newTimer.minutes;
    timerSecEl.textContent = newTimer.seconds;
  }, 1000)
}

export { runTimer };


























// import Countdown from "countdown";

// const timerApp = () => {
//   const daysEl = document.querySelector(".days");
//   const hoursEl = document.querySelector(".hours");
//   const minutesEl = document.querySelector(".minutes");
//   const secondsEl = document.querySelector(".seconds");
//   const futureDate = new Date("March 4 2018 11:00");
//   setInterval(() => {
//     const timespan = Countdown(new Date(), futureDate);
//     const days = timespan.days;
//     const hours = timespan.hours;
//     const minutes = timespan.minutes;
//     const seconds = timespan.seconds;
//     daysEl.innerHTML = days;
//     hoursEl.innerHTML = hours;
//     minutesEl.innerHTML = minutes;
//     secondsEl.innerHTML = seconds;
//   }, 1000)
// };

// timerApp();
