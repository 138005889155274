import "../sass/style.scss";
import Typed from "typed.js";
import "./modal";
import { runTimer } from './timer';
import * as Sneak from './sneak';
import "./reviewModal"

const Jab = (() => {
  const returnToTopEl = document.querySelector(".return-to-top");
  const bodyEl = document.querySelector("body");
  const $navburger = document.querySelector('.nav__burger');
  const $navMenu = document.querySelector('.nav__menu');
  const TIMINGFUNC_MAP = {
    linear: t => t,
    "ease-in": t => t * t,
    "ease-out": t => t * (2 - t),
    "ease-in-out": t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
  };
  const options = {
    strings: ["...", "Master Javascript.", "Build Anything You Want With Javascript.", "Become a Better Developer.", "Land Your First Job."],
    typeSpeed: 50,
    cursorChar: "",
    onComplete: self => {
      setTimeout(function () {
          typed();
      }, 3000);
      // self.el.classList.add("is-highlighted");
    }
  };

  const typed = () => {
    if (window.location.pathname == '/') {
      return new Typed(".js-no-bs", options);
    }
  }

  function scrollTopSmooth(initY, duration = 300, timingName = "linear") {
    const timingFunc = TIMINGFUNC_MAP[timingName];
    let start = null;
    const step = timestamp => {
      start = start || timestamp;
      const progress = timestamp - start,
        // Growing from 0 to 1
        time = Math.min(1, (timestamp - start) / duration);

      window.scrollTo(0, initY - timingFunc(time) * initY);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  function scrollUp() {
    if (returnToTopEl) {
      returnToTopEl.addEventListener("click", e => {
        e.preventDefault();
        scrollTopSmooth(window.scrollY, 300, "linear");
      });
    }
  }

  function showHideScrollButton() {
    window.addEventListener("scroll", () => {
      let currentYposition = window.pageYOffset;
      let innerHeight = window.innerHeight;
      let limit = document.body.offsetHeight - 2;
      if ((currentYposition + innerHeight) >= limit) {
        returnToTopEl.classList.remove("is-hidden");
      } else {
        returnToTopEl.classList.add("is-hidden");
      }
    });
  }

  const checkTimer = _ => {
    if (window.location.href.includes('masterclass')) {
      runTimer();
    }
  }

  const mobileNav = _ => {
    $navburger.addEventListener('click', () => {
      $navburger.classList.toggle('opened')
      $navMenu.classList.toggle('active')
    })
  }

  return {
    checkTimer,
    typed,
    scrollUp,
    showHideScrollButton,
    mobileNav
  };
})();

Jab.checkTimer();
Jab.scrollUp();
Jab.typed();
Jab.showHideScrollButton();
Sneak.init();
Jab.mobileNav();


// temporary

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isSafari) {
  document.querySelector('.gifs').innerHTML = `<p class="is-centered">Oops... for this course, you have to use chrome. If not chrome, at least... Firefox. We don't like Safari! 😈</p>`
}


