const Modal = (() => {
  const openModalEl = document.querySelector(".open-modal");
  const closeModalEl = document.querySelector(".close-modal");
  const modalEl = document.querySelector(".js-modal");
  const openModal = () => {
    if (openModalEl) {
      openModalEl.addEventListener("click", () => {
        modalEl.classList.remove("hide");
        modalEl.classList.add("show");
      })
    }
  }
  const closeModal = () => {
    if (openModalEl) {
      closeModalEl.addEventListener("click", () => {
        modalEl.classList.add("hide");
        modalEl.classList.remove("show");
      })
    }
  }
  return {
    openModal,
    closeModal
  }
})();

Modal.openModal();
Modal.closeModal();
