const $reviews = document.querySelector(".reviews");
const $reviewModal = document.querySelector('.review-modal');

if (!$reviews) {
  console.log('doesnt exist')
} else {


  const getReview = async (index) => {
    const response = await fetch('/reviewsJSON');
    const review = await response.json();
    return review[index]
  }

  const reviewHTML = (review) => {
    let markup = '';
    markup += `
    <div class="review">
      <span class="close-review-modal">&times;</span>
      <div class="review__bio">
        <div class="review__pic">
          <img src="${review.image}" alt=""/>
        </div>
        <div class="review__details">
          <p class="review__name">${review.name}</p>
          <span class="review__country"> ${review.location}
        </div>
      </div>
      <div class="review__stars stars" style="--rating: ${
        review.rating
      };" aria-label="Rating of this product is 4.5 out of 5."></div>
      <p class="review__tagline">
        "${review.tagline}"
      </p>
      <div class="review__note">
        ${review.notes
          .map((paragraph) => {
            return "<p>" + paragraph + "</p>";
          })
          .join("")}
      </div>
    </div>
  `;
    return markup;
  }

  $reviews.addEventListener("click", async (event) => {
    event.preventDefault()
    if (event.target.matches('.review__CR')) {
      const currentReviewIndex = event.target.parentNode.parentNode.getAttribute("id");
      const review = await getReview(currentReviewIndex);
      $reviewModal.lastElementChild.innerHTML = reviewHTML(review);
      $reviewModal.classList.remove("hide");
      $reviewModal.classList.add("show");
    }
  })

  $reviewModal.addEventListener("click", (event) => {
    if (event.target.matches(".close-review-modal")) {
      console.log('this is it')
      $reviewModal.classList.add("hide");
      $reviewModal.classList.remove("show");
    }

    if (event.target.matches(".review-modal") && !event.target.matches(".review")) {
      console.log('outside but not inside')
      $reviewModal.classList.add("hide");
      $reviewModal.classList.remove("show");
    }
  })



}