const $gifNodes = document.querySelectorAll('.gif');
const $gifNodesWithAudio = document.querySelectorAll('.gif--has-audio');

const init = _ => {
  listeners();
}

const listeners = async _ => {
  $gifNodes.forEach(gif => {
    if (!gif.matches('.gif--has-audio')) {
      gif.addEventListener('mouseover', async function () {
        await this.play();
      })
      gif.addEventListener('mouseout', async function () {
        await this.pause();
      })
    }
  })
  $gifNodesWithAudio.forEach(gif => {
    gif.addEventListener('click', async function () {
      this.paused ? await this.play() : await this.pause();
    })
    gif.addEventListener('mouseover', async function () {
      this.muted = !this.muted;
    })
    gif.addEventListener('mouseout', async function () {
      this.muted = !this.muted;
    })
  })
}

export {
  init
};